import { badgeBorderColorMap, badgeVariants } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { getTabIcon } from '@/components/window-keeper/tab-icon';
import { getPerms } from '@/lib/perms';
import { cn } from '@/lib/utils';
import { NoSymbolIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import { toast } from 'sonner';
import { toHandleWidgetData } from '../data/to-handle';
import { useUpdateDashboard } from '../hooks/use-update-dashboard';
import type { DashBoard, Widget, WorkEngagementDashboard } from '../types';
import { reverseWidgetColors } from '../utils';
const filteredWidgetsOnPermissions = (widgets: Widget[]) => {
  return widgets.filter(async (widget) => {
    const modelNames = widget.modelNames;
    if (!modelNames) return false;
    for await (const modelName of modelNames) {
      const perms = await getPerms(modelName);
      return perms.canView;
    }
  });
};

export function EditKeyIndexesModal({
  closeFunction,
  dashboard,
  editIndex,
  editIdentifier,
}: {
  editIndex: number;
  editIdentifier: string | null;
  closeFunction: VoidFunction;
  dashboard: DashBoard | WorkEngagementDashboard;
}) {
  const [selected, setSelected] = useState<string>();
  const keyIndexWidgets = dashboard.widgets.filter(
    (widget) => widget.category === 'key_indexes'
  );
  const keyIndexStatistics = useMemo(
    () => keyIndexWidgets.flatMap((widget) => widget.statistics),
    [keyIndexWidgets]
  );
  const filteredWidgets = useMemo(
    () => filteredWidgetsOnPermissions(toHandleWidgetData),
    []
  );
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useUpdateDashboard();
  const onSubmit = () => {
    if (!selected) return;
    const clonedDashboard = cloneDeep(dashboard);
    const allOtherWidgets = clonedDashboard.widgets.filter(
      (widget) => widget.category !== 'key_indexes'
    );
    const widgetData = toHandleWidgetData.find((widget) =>
      widget.statistics.find((stat) => stat.identifier === selected)
    );
    const stats = widgetData?.statistics.find(
      (stat) => stat.identifier === selected
    );
    if (!widgetData || !stats) return;

    const newWidget: Widget = {
      name: stats.name,
      category: 'key_indexes',
      sub_category: widgetData.sub_category,
      modelNames: widgetData.modelNames,
      statistics: [
        {
          name: stats.name,
          identifier: selected,
          pinned: false,
          color: stats.color,
        },
      ],
    };
    keyIndexWidgets.splice(editIndex, 1, newWidget);
    // place key_indexes widgets at the top always
    const newWidgets = [...keyIndexWidgets, ...allOtherWidgets];
    clonedDashboard.widgets = newWidgets;
    toast.promise(mutateAsync(clonedDashboard), {
      loading: 'Sparar...',
      success: () => {
        closeFunction();
        setSelected(undefined);
        queryClient.invalidateQueries({
          queryKey: ['dashboard'],
        });
        return 'Sparad';
      },
      error: () => {
        return 'Misslyckades med att spara';
      },
    });
  };
  return (
    <Dialog defaultOpen onOpenChange={closeFunction}>
      <DialogContent size='2xl' className='gap-0 p-0'>
        <DialogHeader>
          <DialogTitle>Välj nyckeltal att visa</DialogTitle>
          <Button
            variant={'secondary'}
            size={'icon-sm'}
            onClick={closeFunction}
          >
            <XMarkIcon className='size-4' />
          </Button>
        </DialogHeader>
        <div className='flex h-[500px] flex-col gap-4 overflow-y-auto p-6'>
          {filteredWidgets.map((widget) => (
            <div className='grid gap-4' key={widget.sub_category}>
              <h3>{widget.name.split('-')[1]}</h3>
              <div className='grid grid-cols-4 gap-4'>
                {widget.statistics.map((stat) => {
                  const hasStat = !!keyIndexStatistics.find(
                    (keyStat) => keyStat.identifier === stat.identifier
                  );
                  return (
                    <button
                      key={stat.identifier}
                      disabled={editIdentifier === stat.identifier || hasStat}
                      onClick={() => {
                        if (selected === stat.identifier) {
                          setSelected(undefined);
                        } else {
                          setSelected(stat.identifier);
                        }
                      }}
                      className={cn(
                        buttonVariants({ variant: 'ghost' }),
                        'flex flex-col rounded-md border',
                        selected === stat.identifier && 'bg-accent',
                        editIdentifier === stat.identifier && 'opacity-50',
                        hasStat && 'opacity-50'
                      )}
                    >
                      <div className='flex w-full items-center gap-2'>
                        {stat.modelName ? (
                          getTabIcon(
                            stat.modelName,
                            cn(
                              badgeVariants({
                                variant: reverseWidgetColors[stat.color],
                              }),

                              badgeBorderColorMap[
                                reverseWidgetColors[
                                  stat.color
                                ] as keyof typeof badgeBorderColorMap
                              ],
                              'size-8 shrink-0 rounded-full border p-2'
                            )
                          )
                        ) : (
                          <NoSymbolIcon
                            className={cn(
                              badgeVariants({
                                variant: reverseWidgetColors[stat.color],
                              }),

                              badgeBorderColorMap[
                                reverseWidgetColors[
                                  stat.color
                                ] as keyof typeof badgeBorderColorMap
                              ],
                              'ml-4 size-8 shrink-0 rounded-full border p-2'
                            )}
                          />
                        )}
                        <div className='flex items-center text-left text-xs font-medium'>
                          <span>{stat.name}</span>
                          <span className='text-xs font-normal'>
                            {stat.identifier === editIdentifier || hasStat
                              ? ` (Vald)`
                              : ''}
                          </span>
                        </div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button
            onClick={onSubmit}
            type='submit'
            disabled={isPending || !selected}
          >
            Spara
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
