import { cn } from '@/lib/utils';

export default function PageHeader({
  children,
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  return (
    <div
      className={cn('mb-4 flex w-full items-center justify-between', className)}
    >
      {children}
    </div>
  );
}
