'use client';
import { badgeBorderColorMap, badgeVariants } from '@/components/ui/badge';
import { Button, buttonVariants } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { getTabIcon } from '@/components/window-keeper/tab-icon';
import { getPerms } from '@/lib/perms';
import { cn } from '@/lib/utils';
import { NoSymbolIcon, PencilIcon, PlusIcon } from '@heroicons/react/16/solid';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { toHandleWidgetData } from '../data/to-handle';
import { useGetStatistics } from '../hooks/use-get-statistics';
import type { DashBoard, Widget, WorkEngagementDashboard } from '../types';
import { createUrl, extractIdentifiers, reverseWidgetColors } from '../utils';
import { EditKeyIndexesModal } from './edit-keyindexes-modal';

const filteredWidgetsOnPermissions = (
  widgets: Omit<Widget, 'modelNames'>[]
) => {
  return widgets.filter(async (widget) => {
    const modelNames = toHandleWidgetData.find(
      (d) => d.sub_category === widget.sub_category
    )?.modelNames;
    if (!modelNames) return false;
    for await (const modelName of modelNames) {
      const perms = await getPerms(modelName);
      return perms.canView;
    }
  });
};

export function KeyIndexes({
  dashboards,
  dashboardKey,
  isWorkEngagementDashboard,
}: {
  dashboards: (DashBoard | WorkEngagementDashboard)[] | undefined;
  dashboardKey: string | undefined;
  isWorkEngagementDashboard: boolean;
}) {
  const dashboard = dashboards?.find((d) => d.key === dashboardKey);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editIdentifier, setEditIdentifier] = useState<string | null>(null);
  const unFilteredKeyIndexesWidgets = dashboard?.widgets.filter(
    (widget) => widget.category === 'key_indexes'
  );
  const keyIndexesWidgets = useMemo(() => {
    if (!unFilteredKeyIndexesWidgets) return [];
    return filteredWidgetsOnPermissions(unFilteredKeyIndexesWidgets);
  }, [unFilteredKeyIndexesWidgets]);

  const groupByCategory = Object.groupBy(
    keyIndexesWidgets ?? [],
    (item) => item.category
  );
  const groupBySubCategory = Object.groupBy(
    keyIndexesWidgets ?? [],
    (item) => item.sub_category
  );
  const identifiers = extractIdentifiers(groupByCategory);
  const { data, isFetching } = useGetStatistics({
    options: {
      identifiers,
      widget: 'key-indexes',
      dashboardKey: dashboard?.key,
    },
    enabled: !!dashboard?.key,
  });

  return (
    <>
      {editIndex !== null && dashboard && (
        <EditKeyIndexesModal
          closeFunction={() => {
            setEditIndex(null);
            setEditIdentifier(null);
          }}
          editIndex={editIndex}
          editIdentifier={editIdentifier}
          dashboard={dashboard}
        />
      )}
      <div className='grid grid-cols-2 rounded-md border lg:grid-cols-4'>
        {identifiers?.map((identifier, i) => {
          const subCategory = identifier.split('.')[1];
          const statistics = groupBySubCategory[subCategory]
            ?.filter((item) => item.sub_category === subCategory)
            ?.flatMap((item) => item.statistics);
          const keyIndexesData = data?.find(
            (item) => item.identifier === identifier
          );
          const stat = statistics?.find(
            (item) => item.identifier === identifier
          );

          if (isFetching && !keyIndexesData) {
            return (
              <div
                key={`${i}-pending`}
                className={cn(
                  'flex h-[88px] items-center gap-4 border-r p-3.5 last:border-r-0'
                )}
              >
                <div className='ml-4'>
                  <Skeleton className='size-8 rounded-full border p-2' />
                </div>
                <div className='grid gap-2'>
                  <div className='text-2xl font-bold'>
                    <Skeleton className='h-4 w-12' />
                  </div>
                  <div className='text-sm text-muted-foreground'>
                    <Skeleton className='h-4 w-20' />
                  </div>
                </div>
              </div>
            );
          }
          if (!isFetching && !keyIndexesData) {
            return (
              <div
                key={identifier}
                className={cn(
                  'flex flex-col items-center justify-center gap-2 rounded-none p-4'
                )}
              >
                <NoSymbolIcon className='size-6' />
                <span className='text-sm text-muted-foreground'>
                  Ingen data hittades
                </span>
              </div>
            );
          }
          if (!keyIndexesData || !stat) {
            return null;
          }

          const countForThisStat = keyIndexesData.count;
          const modelName = keyIndexesData.modelName;
          return (
            <div
              className={cn(
                'group relative h-[88px] border-r p-3 last:border-r-0',
                buttonVariants({ variant: 'ghost' }),
                'rounded-none first:rounded-l-md',
                data?.length === 4 && 'last:rounded-r-md',
                'justify-start'
              )}
              key={identifier}
            >
              <Link
                prefetch={false}
                className='flex items-center gap-3'
                href={createUrl(modelName, keyIndexesData.filters)}
              >
                {getTabIcon(
                  modelName,
                  cn(
                    badgeVariants({
                      variant: reverseWidgetColors[stat.color],
                    }),
                    badgeBorderColorMap[
                      reverseWidgetColors[
                        stat.color
                      ] as keyof typeof badgeBorderColorMap
                    ],
                    'ml-4 size-8 shrink-0 rounded-full border p-2'
                  )
                ) || (
                  <NoSymbolIcon
                    className={cn(
                      badgeVariants({
                        variant: reverseWidgetColors[stat.color],
                      }),
                      badgeBorderColorMap[
                        reverseWidgetColors[
                          stat.color
                        ] as keyof typeof badgeBorderColorMap
                      ],
                      'ml-4 size-8 shrink-0 rounded-full border p-2'
                    )}
                  />
                )}
                <div className='grid'>
                  <div className='text-2xl font-bold'>{countForThisStat}</div>
                  <div className='text-xs text-muted-foreground'>
                    {stat.name}
                  </div>
                </div>
              </Link>
              {!isWorkEngagementDashboard && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className='absolute -right-3 -top-3 z-50 hidden rounded-full bg-background group-hover:block'
                      variant={'outline'}
                      size={'icon-sm'}
                      onClick={() => {
                        setEditIndex(i);
                        setEditIdentifier(stat.identifier);
                      }}
                    >
                      <PencilIcon className='size-4' />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className='font-normal'>
                    Redigera
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          );
        })}
        {identifiers.length !== 4 &&
          Array.from({ length: 4 - identifiers.length }).map((_, i) => (
            <Tooltip key={i}>
              <TooltipTrigger asChild>
                <Button
                  variant={'ghost'}
                  // Only allow adding from left to right based on index
                  disabled={
                    !dashboardKey ||
                    isFetching ||
                    i + identifiers.length !== identifiers.length ||
                    isWorkEngagementDashboard
                  }
                  onClick={() => setEditIndex(i + identifiers?.length)}
                  className={cn(
                    'flex h-[88px] items-center justify-center gap-2 rounded-none p-4',
                    i === identifiers?.length - 1 && 'rounded-r-md'
                  )}
                >
                  <PlusIcon className='size-6' />
                </Button>
              </TooltipTrigger>
              <TooltipContent className='font-normal'>
                Lägg till nyckeltal
              </TooltipContent>
            </Tooltip>
          ))}
      </div>
    </>
  );
}
